<template>
  <PageWrapper>
    <Empty class="empty_box" :image-size="200">
      <template slot="description">
        <p>
          {{ $t('register.authority.dialog1') }}
        </p>
        <p>
          {{ $t('register.authority.dialog2') }}
        </p>
        <div class="btn_box">
          <el-button @click="goRegister" type="primary">
            {{ $t('register.authority.openLiveAcc') }}
          </el-button>
        </div>
      </template>
    </Empty>
  </PageWrapper>
</template>

<script>

export default {
  // computed: {
  //   demo() {
  //     return this.$store.state.common.isDemo;
  //   }
  // }
  methods: {
    goRegister() {
      this.$router.push('/register');
    },
  }
};
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/register/authority.scss';
.empty_box {
  height: 80vh;
  padding: 0 40px;

  @include screen-mobile {
    padding: 0 24px;
  }
}

.btn_box {
  margin-top: 24px;
}
</style>
